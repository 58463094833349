import { template as template_9a74977a78d8450293014270263f03fd } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_9a74977a78d8450293014270263f03fd(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
