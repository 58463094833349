import { template as template_46c69375fa9a483f9f810ef704ea9389 } from "@ember/template-compiler";
const SidebarSectionMessage = template_46c69375fa9a483f9f810ef704ea9389(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
